<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="ModalQRUser"
      hide-header
      hide-footer
      centered
      @hidden="hide"
    >
      <div>
        <div class="text-center">
          <vue-qrcode class="qr-width-70" :value="value" />
        </div>
      </div>
      <div class="border-top p-2 text-center btn-confirm-popup">
        <div @click="hide">ปิดหน้าต่างนี้</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";

const moment = require("moment");

export default {
  props: {
    value: {
      required: true,
      type: String
    }
  },
  components: {
    VueQrcode
  },
  mounted() {},
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.f-bold {
  font-weight: 600;
  font-size: var(--text-xl);
  padding: 10px 0;
}
.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid #000;
  border-radius: 50%;
  bottom: 0px;
}
.title-profile {
  font-weight: bold;
  font-size: var(--text-xl);
}
.border-behind {
  border-top: 4px dashed #dfdfdf;
  position: absolute;
  top: 50px;
  width: 100%;
  left: 0;
}
.img-zindex {
  z-index: 1;
  position: relative;
}
.dot {
  display: inline-block;
  top: 30%;
  position: absolute;
  width: 20px;
  height: 35px;
  border-left: 0;
  background-color: gray;
}
.dot-left {
  margin-left: -17px;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.dot-right {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  margin-right: -16px;
}
.color-gray-ft {
  color: #9d9d9d;
}
.qr-width-70 {
  width: 80%;
}

</style>
