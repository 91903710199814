<template>
  <div>
    <div class="content-game-loading" v-if="isLoading">
      <div class="m-auto text-center">
        <b-spinner label="Loading..." class="m-auto spinner-main"></b-spinner>
        <br />
        กรุณารอสักครู่
      </div>
    </div>
    <div @click="handleBodyClick($event)" v-show="!isLoading">
      <div class="container layout">
        <template v-if="tabIndex == 1">
          <div class="container-card">
            <img
              :src="detail.share_label_image"
              class="w-100 rounded-xl h-100 contain"
            />
          </div>

          <div class="text-description">
            {{ detail.share_label }}
            <div v-if="disabled" class="f-xl f-bold text-danger my-2">
              {{ textMessage }}
            </div>
          </div>
        </template>
        <template v-else-if="tabIndex == 2">
          <div class="container-card">
            <b-card class="container-card-1">
              <img :src="detail.coupon_label_image" class="w-100" />
              <div class="mt-2 f-md">
                <div class="f-bold mb-2">{{ detail.privilege_name }}</div>

                <div v-html="detail.description" class="description"></div>
              </div>
            </b-card>
          </div>
          <div class="text-description">
            {{ detail.coupon_label }}
          </div>
        </template>
        <template v-else-if="tabIndex == 3">
          <div class="container-card">
            <b-carousel
              id="carousel-fade"
              img-width="300"
              img-height="300"
              indicators
              :interval="false"
              v-model="imageSelect"
            >
              <b-carousel-slide
                v-for="(image, m) of detail.image_card"
                :key="'image' + m"
                :img-src="image.image_url"
                class="p-3"
              >
                <template #img>
                  <img
                    width="300"
                    height="300"
                    class="w-100 h-auto rounded-xl"
                    :src="image.image_url"
                    alt="image card "
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div>
          <div class="text-description">
            {{ detail.gift_label }}
          </div>
          <!-- Slider main container -->
        </template>
        <b-button
          variant="primary-theme"
          block
          class="py-2 mb-auto mt-3 button-submit"
          @click="onClick()"
          >{{ tabIndex == 3 ? "ส่งเลย" : "ต่อไป" }}</b-button
        >
      </div>
      <div class="panel-options-send" id="panel-options">
        <div class="text-center" @click="togglePanel()">
          <font-awesome-icon
            v-if="showPanel"
            icon="chevron-down"
            class="mb-3"
          />
          <font-awesome-icon v-else icon="chevron-up" class="mb-2" />
        </div>
        <template v-if="showPanel">
          <div class="options-detail f-bold">
            <div>ลิ้งค์ชวนเพื่อน</div>
            <div class="box-link f-bold">
              <div>L I N K</div>
              <div>
                <b-button variant="custom-link" @click="shareLine()">
                  <font-awesome-icon
                    class="mr-2 cursor-pointer"
                    :icon="['fab', 'line']"
                  />
                </b-button>
                <span
                  id="disabled-wrapper"
                  class="d-inline-block"
                  tabindex="0"
                  @click="showTooltip"
                >
                  <b-button
                    variant="custom-link"
                    v-clipboard:copy="inviteLink"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  >
                    <font-awesome-icon
                      class="mr-2 cursor-pointer"
                      :icon="['fa', 'copy']"
                    /> </b-button
                ></span>

                <b-tooltip
                  ref="tooltip"
                  target="disabled-wrapper"
                  placement="top"
                  triggers="click"
                >
                  Copy Link.
                </b-tooltip>
                <b-button variant="custom-link" @click="$refs.qrCode.show()">
                  <font-awesome-icon
                    class="mr-2 cursor-pointer"
                    icon="qrcode"
                  />
                </b-button>
                <b-button
                  variant="custom-link"
                  @click="shareSheet()"
                  v-if="!hideShare"
                >
                  <font-awesome-icon
                    class="cursor-pointer"
                    :icon="['fas', 'share']"
                  />
                </b-button>
              </div>
            </div>
            <div class="d-flex align-items-center">
              เพื่อนที่ชวนแล้ว
              <span class="badge-count"
                >{{ count.count }}
                <span v-if="count.all > 0">/{{ count.all }}</span></span
              >
              <!-- <font-awesome-icon
            v-if="isShow"
            icon="chevron-down"
            class="ml-auto"
            @click="isShow = !isShow"
          /> -->
              <font-awesome-icon
                icon="chevron-right"
                class="ml-auto"
                @click="gotoList()"
              />
            </div>
            <!-- <Transition name="slide-fade"> -->
            <b-card class="shadow px-2 py-3" no-body v-if="count.all > 0">
              <b-row class="container-invite m-0">
                <b-col
                  cols="2"
                  v-for="(item, index) in count.all"
                  :key="index"
                  :class="[
                    'invite-cumulative',
                    index > count.count - 1 && 'opacity'
                  ]"
                >
                  <font-awesome-icon :icon="['fas', 'gift']" /> </b-col
              ></b-row>
            </b-card>
            <!-- </Transition> -->
          </div>
        </template>
        <ModalQrCode
          ref="qrCode"
          :value="`https://liff.line.me/${$liff_ID_Login}/invite/${this.detail.id}?inviteKey=${this.detail.url_key}&user_guid=${this.$store.state.shortProfile.user_guid}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalQrCode from "@/components/profile/modal/ModalQrCode";
export default {
  components: {
    ModalQrCode
  },

  data() {
    return {
      brandName: process.env.VUE_APP_BRAND,
      tabIndex: this.$route.query.tabIndex || 1,
      showPanel: false,
      isShow: true,
      height: "50vh",

      detail: {
        id: this.$route.params.id,
        name: null,
        url_key: "",
        reward_point: 0,
        reward_privilege: 0,
        limit: 0,
        reward_type_id: 0,
        reward_freind_condition_type_id: 0,
        reward_friend_point: 0,
        reward_friend_privilege: 0,
        status: 0,
        valid_from: "",
        valid_to: "",
        invitation_message: "",
        card_message: "",
        banner: null,
        background_page: null,
        share_label: "",
        share_label_image: "",
        coupon_label: "",
        coupon_label_image: "",
        gift_label: "",
        user_invited: 0,
        segment_id: 0,
        segment: null,
        image_card: []
      },
      imageSelect: 0,
      isLoading: true,
      count: { count: 0, all: 0 },
      inviteLink: "",
      lineProfile: {},
      textMessage: "",
      disabled: false,
      hideShare: false,
      filter: {
        SocialProvider: "line",
        SocialId: ""
      },
      noReceive: false,
      toolTipShow: false,
      replaceString: false
    };
  },
  // metaInfo: {
  //   title: "Default App Title",
  //   titleTemplate: "%s | vue-meta Example App",
  //   htmlAttrs: {
  //     lang: "en-US"
  //   },
  //   meta: [
  //     { charset: "utf-8" },
  //     {
  //       property: "og:title",
  //       content: `Default App Title`
  //     },
  //     {
  //       name: "description",
  //       content: "An example Vue application with vue-meta."
  //     },
  //     { name: "viewport", content: "width=device-width, initial-scale=1" },
  //     {
  //       property: "og:image",
  //       content:
  //         "https://media.istockphoto.com/id/1322277517/photo/wild-grass-in-the-mountains-at-sunset.jpg?s=612x612&w=0&k=20&c=6mItwwFFGqKNKEAzv0mv6TaxhLN3zSE43bWmFN--J5w="
  //     }
  //   ]
  // },
  // metaInfo() {
  //   return {
  //     title: `${this.detail.invitation_message || "test"}`,
  //     meta: [
  //       {
  //         name: "description",
  //         content: this.detail.card_message || ""
  //       },
  //       {
  //         property: "og:title",
  //         content: `${this.detail.invitation_message || "test"}`
  //       },
  //       { property: "og:site_name", content: this.brandName },
  //       {
  //         property: "og:description",
  //         content: this.detail.card_message || ""
  //       },

  //     ]
  //   };
  // },iu
  async created() {
    // document.title = `${this.brandName} on Test`;
    if (!this.$route.query.tabIndex) {
      this.$router.replace({
        path: `/invite/${this.$route.params.id}`,
        query: {
          ...this.$route.query,
          tabIndex: this.tabIndex
        }
      });
    }
    if (this.getMobileOperatingSystem() == "Android") this.hideShare = true;
    try {
      await this.$store.dispatch("getUserApi");
    } catch (error) {}
    await this.liffLogin();
    await this.getData();
  },
  mounted() {},

  watch: {
    "$route.query"(val) {
      let { tabIndex } = val;
      this.tabIndex = tabIndex;
    }
  },
  methods: {
    showTooltip() {
      this.$refs.tooltip.$emit("open");
    },
    async liffLogin() {
      if (this.$route.query.inviteKey) {
        if (this.$liff.isLoggedIn()) {
          this.getProfileList();
        }
      }
    },
    getProfileList() {
      this.$liff.getProfile().then(profile => {
        this.$cookies.set("user_profile_token", profile, 60 * 60 * 24 * 30);
        this.lineProfile = profile;
        this.LoginExternal();
      });
    },
    async LoginExternal() {
      this.filter.SocialId = this.lineProfile.userId;
      this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/LoginExternal`,
          this.filter
        )
        .then(async data => {
          if (data.result != 0) {
            this.disabled = true;
            this.textMessage = "ขออภัย คุณเป็นสมาชิกแล้ว";
            this.$cookies.set("hourglass_token", data.detail.token);
          }
        });
    },
    onClick() {
      if (this.$route.query.inviteKey) {
        if (this.disabled) {
          return this.$router.replace("/profile");
        }

        return this.$router.replace("/validatetelephone");
      }
      if (this.tabIndex == 3) {
        return this.shareLine();
      }
      this.$router.push({
        path: `/invite/${this.$route.params.id}`,
        query: { ...this.$route.query, tabIndex: Number(this.tabIndex) + 1 }
      });
    },
    togglePanel() {
      if (!this.showPanel) {
        $("#panel-options").css("height", `calc(${this.height} + 10%)`);
        $("#panel-options").css("overflow-y", `auto`);
      } else {
        $("#panel-options").css("height", "35px");
        $("#panel-options").css("overflow-y", `hidden`);
      }
      this.showPanel = !this.showPanel;
    },
    handleBodyClick(e) {},
    shareLine() {
      this.$liff
        .shareTargetPicker(
          [
            {
              type: "flex",
              altText: this.detail.invitation_message,
              contents: {
                type: "bubble",
                hero: {
                  type: "image",
                  url: this.detail.image_card[this.imageSelect].image_url,
                  size: "full",
                  aspectRatio: "1:1",
                  aspectMode: "cover"
                },
                body: {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "text",
                      text: this.detail.card_message,
                      wrap: true
                    },
                    {
                      type: "button",
                      action: {
                        type: "uri",
                        label: "รับของขวัญ",
                        uri: this.inviteLink
                      },
                      style: "secondary",
                      height: "sm",
                      margin: "sm"
                    }
                  ]
                }
              }
            }
          ],
          {
            isMultiple: true
          }
        )
        .then(function (res) {
          if (res) {
            console.log(res);
            // succeeded in sending a message through TargetPicker
            console.log(`[${res.status}] Message sent!`);
          } else {
            // sending message canceled
            console.log("TargetPicker was closed!");
          }
        })
        .catch(function (error) {
          console.log(error);
          // something went wrong before sending a message
          console.log("something wrong happen");
        });
    },
    async getData() {
      try {
        // $("#panel-options").css("display", `flex`);
        this.replaceString = false;
        const re3 = await this.$axios(
          `${process.env.VUE_APP_API}/api/v1/FriendGetFriend/get_image/${
            this.$route.params.id
          }/${
            this.$route.query.user_guid ||
            this.$store.state.shortProfile.user_guid
          }`
        );
        if (re3.result == 0) {
          this.disabled = true;
          if (re3.message == "ขออภัยด้วย สิทธิเต็มตามจำนวนจำกัด") {
            this.noReceive = true;
            if (this.$route.query.inviteKey) {
              this.$router.replace({
                path: `/invite/${this.$route.params.id}`,
                query: { ...this.$route.query, noReceive: true }
              });
            }
          }
          this.textMessage = re3.message;
          // $("#panel-options").css("display", `none`);
        }

        this.inviteLink = `https://liff.line.me/${process.env.VUE_APP_LIFF_ID_LOGIN}/invite/${re3.detail.id}?inviteKey=${re3.detail.url_key}&user_guid=${this.$store.state.shortProfile.user_guid}`;
        let string = re3.detail.invitation_message;
        if (string.includes("{{Referral Link}}")) {
          re3.detail.invitation_message = string.replace(
            "{{Referral Link}}",
            this.inviteLink
          );
          this.replaceString = true;
        }

        // $("meta[property=og\\:image]").attr(
        //   "content",
        //   re3.detail.share_label_image
        // );
        this.detail = re3.detail;
        let layout = document.getElementsByClassName("layout")[0];
        layout.style.backgroundImage = `url('${re3.detail.background_page}')`;
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
      try {
        const re4 = await this.$axios(
          `${process.env.VUE_APP_API}/api/v1/FriendGetFriend/get_user_count/${this.$route.params.id}`
        );
        this.count = re4.detail;
        if (re4.detail.all == 0) this.height = "15vh";
      } catch (err) {
        console.log(err);
      }
    },
    async convertShortLink() {
      const apiUrl = `https://api.shrtco.de/v2/shorten?url=${originalLink}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        console.log(data);
        let link = document.createElement("li");
        link.className = "output";
        link.innerHTML = `<a href="${data.result.full_short_link}" target="_blank">${data.result.full_short_link} </a>`;
        parent.prepend(link);
      } catch (e) {
        console.error(e);
      }
    },
    async shareSheet() {
      try {
        var shareData = {};
        if (this.replaceString)
          shareData = {
            title: this.detail.invitation_message,
            text: this.detail.invitation_message,
            url: ""
          };
        else
          shareData = {
            title: this.detail.invitation_message,
            text: this.detail.invitation_message,
            url: this.inviteLink
          };
        await navigator.share(shareData);
      } catch (error) {
        console.log(error);
      }
    },

    gotoList() {
      this.$router.push(`/invite/${this.$route.params.id}/list`);
      this.togglePanel();
    },
    copyText() {
      if (this.replaceString)
        navigator.clipboard.writeText(this.detail.invitation_message);
      else
        navigator.clipboard.writeText(
          this.detail.invitation_message + " " + this.inviteLink
        );
    },
    onCopy: function (e) {
      setTimeout(() => {
        this.$refs.tooltip.$emit("close");
      }, 1000);
      // this.$swal("success", "You just copied", "success");
    },
    onError: function (e) {
      this.$swalswal("Failed to copy", `${e.text}`, "error");
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    }
  }
};
</script>

<style scoped lang="scss">
.layout {
  min-height: calc(100vh - 80px);
  height: 100%;
  background-image: url(../../../assets/images/fgf/1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: space-between; */
}
.card {
  border-radius: 0.75rem !important;
  border-color: white !important;
}
.panel-options-send {
  position: fixed;
  bottom: 0;
  z-index: 20;
  left: 0;
  height: 35px;
  background: white;
  border-radius: 1.25rem 1.25rem 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: var(--text-xl);
  overflow-y: hidden;
  /* align-items: center; */
  /* justify-content: center; */
  padding: 0.5rem 1rem;
  box-shadow: 0 -2px 16px -9px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.options-detail {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  color: var(--primary-color);
}
.box-link {
  border-radius: 0.5rem;
  border: 1px dashed var(--primary-color);
  padding: 0.5rem 1rem;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.badge-count {
  padding: 0.25rem;
  font-size: 75%;
  margin-top: 0.25rem;
  width: 50px;
  text-align: center;
  height: auto;
  /* aspect-ratio: 1; */
  margin-left: 0.5rem;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  border-radius: 0.75rem;
}
.invite-cumulative {
  display: flex;
  background: var(--secondary-color);
  color: var(--primary-color);
  text-align: center;
  padding: 0.5rem 0.5rem;
  border-radius: 50%;
  font-size: 2.2rem;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  max-width: 75px;
  height: auto;
  max-height: 75px;
  aspect-ratio: 1;
}
.container-invite {
  row-gap: 0.5rem;
  column-gap: 0.75rem;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.opacity {
  opacity: 0.5;
}
.swiper {
  height: 600px;
  width: 100%;
}
::v-deep .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
::v-deep .carousel-indicators {
  bottom: -30%;
}
::v-deep .carousel-indicators .active {
  background-color: var(--primary-color);
}
.w-80 {
  width: 80%;
}
.content-game-loading {
  height: calc(100vh - 80px);
  position: absolute;
  top: 0;
  width: 100%;
  background: white;
  z-index: 1;
  display: flex;
}
.f-h {
  height: 120px;
}
.container-card {
  margin: auto 0 0 0;
  width: 80%;
}
.container-card-1 {
  width: 100%;
}
@media (min-width: 376px) and (max-width: 450px) {
  .container-card {
    height: 300px;
    margin-bottom: 5rem;
  }
  .text-description {
    margin-top: 0 !important;
  }
}
@media (max-width: 375px) {
  .container-card {
    margin: 15px 0 0 0;
    height: auto;
    max-width: 65%;
  }
  .text-description {
    margin-top: 2.5rem !important;
  }
}

::v-deep.description p {
  margin-bottom: 0;
  -webkit-line-clamp: 2;
  font-size: var(--text-md);
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.button-submit {
  position: fixed;
  bottom: 50px;
  width: 90% !important;
}
.text-description {
  height: 120px;
  font-weight: bold;
  font-size: var(--text-lg);
  text-align: center;
  margin-bottom: auto;
  margin-top: auto;
  padding-left: 1rem;
  padding-bottom: 3rem;
  padding-right: 1rem;
  padding-top: 1rem;
}
.contain {
  object-fit: contain;
}
</style>
